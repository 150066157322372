<template>
  <Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <CampaignTable/>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import CampaignTable from '../components/tables/CampaignTable'
import sections from './defaultNavigationDrawerSections'

export default {
  name: 'CampaignList',
  components: { CampaignTable, Layout },
  data () {
    return {
      sections: sections,
      title: this.$t('campaigns.sectionName')
    }
  }
}
</script>
